import '../../styles/pages/spring-summer-2022/transforming-the-future.scss';

import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2022-stories/more-stories"

// import Link from "gatsby-plugin-transition-link/AniLink";
// import { Parallax, Background } from 'react-parallax';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import socialBanner from "../../images/spring-summer-2022/social-banners/transforming-the-future.jpg";


import heroImage from "../../images/spring-summer-2022/transforming-the-future/hero.jpg";
import titleGraphic from "../../images/spring-summer-2022/transforming-the-future/title-graphic.svg";

import gradilla from "../../images/spring-summer-2022/transforming-the-future/gradilla.jpg";
import oseguera from "../../images/spring-summer-2022/transforming-the-future/oseguera.jpg";

import moralesFamily from "../../images/spring-summer-2022/transforming-the-future/morales-family.jpg";
import genericFamily from "../../images/spring-summer-2022/transforming-the-future/generic-family.jpg";
import cordon from "../../images/spring-summer-2022/transforming-the-future/cordon.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Transforming the Future';
    var pageDesc = 'First-Generation Students Set the Path for Success';
    var slug = 'transforming-the-future';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${heroImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white',
      height: '100vh'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img src={titleGraphic} alt={pageTitle} /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">First-Generation Students Set the Path for Success</p>
            <span className="animated fadeInDown delay-1s byline">By Valerie Orleans / Photos By Matt Gush</span>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p><span className='intro-text'>Tens of thousands of first-generation students have earned their degrees at Cal State Fullerton</span> over the years, but it hasn’t always been easy. As the first in their families to earn a college degree, first-gen students are often tasked to learn, on their own, about the intricacies of registration, applying for financial aid, scheduling classes, handling coursework and managing stress. When these students run into challenges, they can question themselves and their abilities.</p>
          
          <h2>Understanding the Struggles of First-Gen Students</h2>
          <p>Alexandro Gradilla, associate professor of Chicana and Chicano studies, understands the challenges faced by first-generation students because he was a first-generation student. In fact, he often shares stories of his own struggles with his students, including the fact that he was put on academic probation as a college freshman at UC Berkeley.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className='circle align-outside align-left black-caption-background '>
            <img src={gradilla} alt="Alexandro Gradilla" />
            {/* <figcaption>Alexandro Gradilla</figcaption> */}
          </figure>

          <p>“I came out of a program for gifted students at my San Diego high school,” he says. “It wasn’t that I wasn’t academically capable of handling college-level work, it’s just that as a first-generation student, I didn’t know where to turn for support. My parents immigrated from Mexico and while they encouraged me to earn a college degree, they didn’t understand the American system of higher education. Most first-gen students can handle the coursework, but many of the systems — how to register, how to apply for financial aid, where to find tutoring or academic support — are often unknown to them.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Gradilla also notes that many first-gen students feel extreme pressure to perform and perform well, adding to their anxieties.</p>

          <p>“If you don’t have anyone else who has experienced the same transitions as you, it’s hard to communicate these feelings to your professors, your family members and friends,” he explains. “First-gen students often have ‘imposter syndrome,’ where they feel they will be ‘discovered’ as lacking. What they don’t understand is that almost everyone has felt that way at one time or another.”</p>
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <h2>How Cal State Fullerton Can Help</h2>
          <p>Since more than 30% of students at CSUF are the first in their families to attend college, numerous programs can help them navigate the campus and learn which resources are available to them.</p>

          <p>“That’s why so much emphasis is placed on ensuring first generation students are aware of programs to assist them in their transition to Cal State Fullerton, whether they enter as freshmen, transfer students or decide to return to school after years in the workforce or at home,” says Tonantzin Oseguera, vice president for student affairs and a first-generation graduate.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <blockquote className='pullquote'>
            <img src={oseguera} className="pullquote-image" alt="Tonantzin Oseguera" />
            <p>“You won’t just transform your future but the lives of everyone in your family. Not only that, Cal State Fullerton needs you, too. We need the gifts of strength you bring to our university — your courage and resilience. We thank you for your character and drive. You have the tenacity to be the first in your family to pursue a college education. And while you may be the first in your family, you won’t be the last.”</p>
            <cite>Tonantzin Oseguera, vice president for student affairs (and a first-generation graduate)</cite>
          </blockquote>
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <h2>What It’s Like for Students</h2>

          <figure className='align-right align-outside large'>
            <img src={moralesFamily} alt="Family portrait" />
            <figcaption>Tatiana Morales and her family</figcaption>
          </figure>

          <p>As the oldest of four children born to immigrants from Guatemala, Tatiana Morales knew she wanted to set an example for her younger brothers.</p>

          <p>“Like many immigrants, my parents wanted to create a better future for their children,” Morales says. “They knew that in the United States, there would be amazing opportunities for us.</p>
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“My first semester at Cal State Fullerton was eye-opening. I wanted to pursue a profession that tied into the values of hard work, dignity and education that my parents provided. That’s why I chose to major in public health. My parents always said, ‘Todos comenzamos de abajo’ (‘We all start from the bottom’). That’s what drives me every day.”</p>

          <p>Without family to help guide her, Morales turned to such Cal State Fullerton programs  as Gaining Early Awareness and Readiness for Undergraduate Programs or <a href="https://www.fullerton.edu/gearup/"  target="_blank" rel="noreferrer">GEAR UP</a>, <a href="https://www.fullerton.edu/talentsearch/"  target="_blank" rel="noreferrer">Educational Talent Search</a>, and <a href="https://www.fullerton.edu/cice/programs/ases/"  target="_blank" rel="noreferrer">Advancement via Individual Determination</a>.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>These programs showed Morales what the campus has to offer. At Cal State Fullerton, she received career advising, financial aid and introductions to different programs and clubs to help her engage with other first-generation students as well as the larger student body.</p>

          <p>“I took a tour of the campus and instantly fell in love with CSUF,” she says. “I knew I would become a Titan and I’m proud to say that today I am part of an innovative community that provides me with a strong foundation to help me achieve success.</p>
          
          <p>“I appreciate how Cal State Fullerton celebrates the triumph of every student,” she says. “At the end of the day, we are all a family with the desire to grow, transform and empower.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <h2>Reaching Out to First-Generation Students</h2>
          <p>“Barriers faced by first-gen students might include a hold on their account registration because they aren’t aware of mandatory advising requirements or they haven’t completed Title IX training,” says Oseguera. “They may have to sign a promissory note or pay a bill and they don’t know that there’s money in their account from financial aid. When problems occur, they panic because they don’t understand what’s creating the problem, let alone how to fix it.</p>

          <p>“They often don’t want to ask for help because they perceive that everyone else knows how to resolve the issues,” she continues. “That’s why as soon as we can, often during orientation, we point out that most students feel overwhelmed at some point and they shouldn’t feel bad about reaching out to us. We want them to reach out.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“This fall, we started ‘First Gen Week.’ More than 1,000 students showed up. We point out how proud we are of them. We tell them, ‘You’ve overcome obstacles that many others haven’t had to deal with…and we think you’re cool.’</p>

          <p>“This changes how they see themselves. They feel proud about who they are and what they’re trying to accomplish. We tell them about the resources that are available and let them know they’re not the only ones who may feel intimidated. </p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“When they see the diversity of our campus, it normalizes the circumstances for them. We also encourage them to ‘embed’ in the campus through clubs and programs such as the Male Success Initiative, Veterans Resource Center, adult reentry, Tuffy’s Graduation Scholars — any program that speaks to them. Whatever their circumstances and interests, we probably have a program.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <h2>Transitions Can Be Hard…But Get Involved</h2>
          <p>“My mother is very supportive of me going to college, but as a single mom, she had to ensure we wouldn’t go into debt,” says Tiffany Cordon. “The opportunities that Cal State Fullerton offered, both in terms of academic and professional development, were very helpful in making my decision to come here.</p>

          <p>“Coming straight from high school was quite a transition. I lived on campus my first semester and it was great because I became more independent and made connections with fellow students. I began to build a network that laid the foundation for my college years.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className='align-left align-outside large'>
            <img src={cordon} alt="Tiffany Cordon presenting to two individuals" />
            <figcaption>Tiffany Cordon</figcaption>
          </figure>

          <p>Cordon is currently in CSUF’s business honors program and serves as elections director for Associated Students Inc. She is also active in Tuffy’s Graduation Scholars, the Center for Leadership and several other campus programs.</p>

          <p>Her advice for other first-gen students is to get involved right from the start.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“It can be intimidating at first, especially if you don’t have someone at home who has been to college before,” she admits. “But the sooner you start exploring campus and what it offers, the more it will feel like home.”</p>

          <BrowserView>
          <aside className='related-stories align-outside'>
            <h2>Helping First-Gen Students Launch Their Careers</h2>
            <p>It’s not enough to help first-gen students navigate all the campus systems and regulations. Many first-gen graduates are also not sure how to launch their careers.</p>
  
            <p>The CSUF Career Center offers guidance on building a resume, developing interviewing skills and determining employers’ expectations.</p>
  
            <p>“First-generation students are often applying for jobs that are very different from the ones their parents hold, so again, they may need more help in preparing to enter the workforce,” says Stephanie Reyes, senior associate director in the Career Center. “We have the <a href="https://www.fullerton.edu/career/recruiters/i_am_first.php" target="_blank" rel="noreferrer">‘I Am First’ program</a>  that focuses on career readiness and is based on feedback from people who have gone through the process.</p>
  
            <p>“Students take an inventory of their strengths and define what they can bring to the workplace. We connect them to internships and alumni who are in the industries they are interested in. There are mentors and potential employers who conduct mock interviews. They learn to feel more comfortable and confident.</p>
  
            <p>“We know once a student is on a path to career success, the whole family benefits as well as succeeding generations.</p>
          </aside>
          </BrowserView>

          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <h2>If You Fall Behind, There’s Someone to Pick You Up</h2>
          <p>“Higher education can be very challenging,” Gradilla says. “Key teachers in my life kept me focused. I like the analogy of a trapeze artist. If you miss the first grab of the bar, someone is assigned to grab you. I was lucky that there were people to grab me.”</p>

          <p>Although Gradilla benefited from better teacher-to-student ratios, other motivated students and more challenging work when he was in his school’s gifted program, it did remove him from his community, neighborhood and church friends.</p>

          <p>“I was one of two students of color in that program,” he recalls. “There were good days but also bad days. Sometimes, racism would creep into the curriculum. If there was violence in Tijuana, some of my classmates would look at me as if I somehow knew what was going on.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“The other disadvantage was using me as a model to other students: ‘Why can’t you do what Alexandro is doing?’ It was not a compliment. The undercurrent for me was ‘You’re not like the other Mexican kids.’ Even when I received a scholarship to Berkeley, some of my classmates would look at me and say, ‘Well, you got in because you’re Mexican.’ I mean, these people knew me! They knew I earned good grades. They were my friends. Now, because I was succeeding, it wasn’t because of my achievements? That added some academic baggage. I think many first-gen students also go through that, too.</p>

          <p>“I was lucky that Berkeley reached out to me because I didn’t know about application deadlines. That’s why it’s important for universities to reach out to first-gen students. To our students, I say, ‘You didn’t end up here accidentally. We want you.’ That changes their outlook.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“I have the privilege of working with Latinx students across campus. I often ask them to tell me what’s going on. One cultural issue is that Latinx people are very private. We don’t like putting our ‘dirty laundry’ out there or admitting failure.</p>

          <p>“On the other hand, if students don’t let us know what’s going on, we’ll assume the worst. I tell my students they need to protect their schedules and their transcripts.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“For instance, I may give an eight-page assignment and a student will turn in one page. I’ll explain, ‘You can’t get in the habit of accepting defeat…give me my eight pages. Yes, I could give you an F but that doesn’t do either of us any good.’</p>

          <p>“Many of these students are working full time, helping out at home or don’t always have access to a quiet place to study. There needs to be an understanding, even in terms of grading policies, that these are things you need to be mindful of…not every student is the same. I like to give students second chances. I want to develop an environment where they will perform academically. </p>

          
          <p>“It’s seeing them and holding them accountable. Go back and do the work, and they do. Learn that skill. Isolate and get it done. Schedule time for studying. People assume if you’re not physically at school that you’re free. But remember, you need study time.” </p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className='align-wide'>
            <img src={genericFamily} alt="Family portrait" />
          </figure>
          
          <h2>The Importance of Parents</h2>
          <p>“Families need to understand what students will experience. We tell them if they want their sons and daughters to be successful, they need time to study, go to the library, participate in different groups and programs. College is not the same as high school. There is a lot more reading, research, writing, group work, exams. They won’t always be able to make it to family gatherings. It’s not going to be the same,” says Oseguera.</p>

          <p>“That’s why we’ve developed outreach programs for parents. Some programs are offered during orientation; some are concurrent with other cohort programs. We need to help families understand the role they play in students’ success.”  <span className='end-mark' /></p>
          </ScrollAnimation>

          <MobileView>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <aside className='related-stories align-outside'>
            <h2>Helping First-Gen Students Launch Their Careers</h2>
            <p>It’s not enough to help first-gen students navigate all the campus systems and regulations. Many first-gen graduates are also not sure how to launch their careers.</p>
  
            <p>The CSUF Career Center offers guidance on building a resume, developing interviewing skills and determining employers’ expectations.</p>
  
            <p>“First-generation students are often applying for jobs that are very different from the ones their parents hold, so again, they may need more help in preparing to enter the workforce,” says Stephanie Reyes, senior associate director in the Career Center. “We have the <a href="https://www.fullerton.edu/career/recruiters/i_am_first.php" target="_blank" rel="noreferrer">‘I Am First’ program</a>  that focuses on career readiness and is based on feedback from people who have gone through the process.</p>
  
            <p>“Students take an inventory of their strengths and define what they can bring to the workplace. We connect them to internships and alumni who are in the industries they are interested in. There are mentors and potential employers who conduct mock interviews. They learn to feel more comfortable and confident.</p>
  
            <p>“We know once a student is on a path to career success, the whole family benefits as well as succeeding generations.”</p>
          </aside>
          </ScrollAnimation>
          </MobileView>

          <p className="learn-more">Support First-Gen Students<br /><a href="https://campaign.fullerton.edu/students" target="_blank" rel="noreferrer">campaign.fullerton.edu/students</a></p>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 